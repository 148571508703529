<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <BMIComponent :form="form" @setRating="v => (bmiRating = v)" :readonly="readonly" @setNotDirty="setNotDirty" />

    <div class="columns">
      <div class="column">
        <b-field label="Continence" horizontal class="label-long">
          <EnumDropDown v-model="form.continence" :data="continenceEnum" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Skin Type" horizontal class="label-long">
          <EnumDropDown v-model="form.skinType" :data="skinTypeEnum" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Mobility" horizontal class="label-long">
          <EnumDropDown v-model="form.mobility" :data="mobilityEnum" :disabled="readonly" />
        </b-field>
      </div>
    </div>

    <WeightLoss
      :subjectiveUsePercent="false"
      :disabled="readonly"
      :currentWeight="form.weight"
      :previousWeight="form.previousWeight"
      :previousWeightDate="form.previousWeightDate"
      :unplannedWeightLoss="form.unplannedWeightLoss"
      @update:unplannedWeightLoss="v => (form.unplannedWeightLoss = v)"
      :weightLoss="form.weightLoss"
      @update:weightLoss="v => (form.weightLoss = v)"
    />

    <div class="columns">
      <div class="column">
        <b-field label="Eating poorly" horizontal class="label-long">
          <YesNoUnsurePicker v-model="form.eatingPoorly" name="eatingPoorly" message="(Lack of Appetite)" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column"></div>
      <div class="column"></div>
    </div>

    <div class="columns">
      <div class="column label-long">
        <b-field label="Tissue Malnutrition" horizontal>
          <b-checkbox v-model="form.multipleOrganFailure" :disabled="readonly">Multiple Organ Failure</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.terminalCachexia" :disabled="readonly">Terminal Cachexia</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.singleOrganFailure" :disabled="readonly">Single Organ Failure</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.peripheralVascular" :disabled="readonly">Peripheral Vascular</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.anaemia" :disabled="readonly">Anaemia</b-checkbox>
        </b-field>
        <b-field horizontal class="label-long">
          <b-checkbox v-model="form.smoking" :disabled="readonly">Smoking</b-checkbox>
        </b-field>
      </div>
      <div class="column label-long">
        <b-field label="Neurological Deficit" horizontal>
          <b-checkbox v-model="form.diabetesMsCva" :disabled="readonly">Diabetes, MS, CVA</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.motorSensory" :disabled="readonly">Motor / Sensory</b-checkbox>
        </b-field>
        <b-field horizontal> <b-checkbox v-model="form.paraplegia" :disabled="readonly">Paraplegia</b-checkbox> </b-field>
      </div>
      <div class="column label-long">
        <b-field label="Surgery / Trauma" horizontal>
          <b-checkbox v-model="form.table6Hours" :disabled="readonly">On table > 6 hours</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.orthopaedicSpinal" :disabled="readonly">Orthopaedic / Spinal</b-checkbox>
        </b-field>
        <b-field horizontal>
          <b-checkbox v-model="form.table2Hours" :disabled="readonly">On table > 2 hours</b-checkbox>
        </b-field>
      </div>
    </div>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import assessmentMixin from "@/mixins/assessmentMixin";
import BMIComponent from "@/components/assessments/BMIComponent";
import WeightLoss from "@/components/assessments/WeightLoss";

import EnumDropDown from "@/components/EnumDropDown";
import YesNoUnsurePicker from "@/components/YesNoUnsure";

import Continence from "@/enums/continence";
import SkinType from "@/enums/skinType";
import Mobility from "@/enums/mobility";
import YesNoUnsure from "@/enums/yesNoUnsure";
import Gender from "@/enums/gender";
import Risk from "@/enums/risk";
import AssessmentType from "@/enums/assessmentType";

export default {
  components: { AssessmentForm, BMIComponent, EnumDropDown, YesNoUnsurePicker, WeightLoss },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.Waterlow,
      form: this.getClearFormObject(),
      continenceEnum: Continence.Lookup,
      skinTypeEnum: SkinType.Lookup,
      mobilityEnum: Mobility.Lookup,
      bmiRating: null
    };
  },

  computed: {
    weightLossScore() {
      const weightLoss = this.form.weightLoss;
      if (weightLoss < 0.5) return 0;
      if (weightLoss < 5) return 1;
      if (weightLoss < 10) return 2;
      if (weightLoss < 15) return 3;
      return 4;
    },
    ageScore() {
      const age = this.form.age || 0;
      if (age < 14) return 0;
      if (age < 50) return 1;
      if (age < 65) return 2;
      if (age < 75) return 3;
      if (age < 81) return 4;
      return 5;
    },
    tissueScore() {
      var score = this.form.multipleOrganFailure ? 8 : 0;
      if (this.form.terminalCachexia) score += 8;
      if (this.form.singleOrganFailure) score += 5;
      if (this.form.peripheralVascular) score += 5;
      if (this.form.anaemia) score += 2;
      if (this.form.smoking) score += 1;
      return score;
    },
    nerorlogicalScore() {
      var score = this.form.diabetesMsCva ? 4 : 0;
      if (this.form.motorSensory) score += 5;
      if (this.form.paraplegia) score += 6;
      if (score > 6) score = 6;
      return score;
    },
    surgeryScore() {
      var score = this.form.table6Hours ? 8 : 0;
      if (this.form.orthopaedicSpinal) score += 5;
      if (this.form.table2Hours) score += 5;
      return score;
    },
    medicationScore() {
      return this.form.cytotoxic || this.form.antiInflammatory || this.form.steroids ? 4 : 0;
    },
    nutritionScore() {
      // prettier-ignore
      return this.form.unplannedWeightLoss === YesNoUnsure.Enum.Yes
        ? this.weightLossScore
        : this.form.eatingPoorly === YesNoUnsure.Enum.Yes
          ? 1
          : this.form.eatingPoorly === YesNoUnsure.Enum.Unsure
            ? 2
            : 0;
    },

    score() {
      var score = this.form.gender === Gender.Enum.Male ? 1 : 2;
      score += this.ageScore;
      score += this.bmiRating;
      score += this.form.continence || 0;
      score += SkinType.Score[this.form.skinType || 0];
      score += this.form.mobility || 0;
      score += this.nutritionScore + this.tissueScore + this.nerorlogicalScore + this.medicationScore + this.surgeryScore;
      return score;
    },
    rating() {
      const score = this.score;
      if (score < 10) return Risk.Enum.LowRisk;
      if (score < 15) return Risk.Enum.MediumRisk;
      if (score < 20) return Risk.Enum.HighRisk;
      return Risk.Enum.VeryHighRisk;
    }
  },

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: null,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        height: null,
        weight: null,
        heightId: null,
        weightId: null,
        heightDate: null,
        weightDate: null,

        continence: null,
        skinType: null,
        mobility: null,
        unplannedWeightLoss: null,
        weightLoss: null,
        eatingPoorly: null,
        multipleOrganFailure: false,
        terminalCachexia: false,
        singleOrganFailure: false,
        peripheralVascular: false,
        anaemia: false,
        smoking: false,
        diabetesMsCva: false,
        motorSensory: false,
        paraplegia: false,
        table6Hours: false,
        orthopaedicSpinal: false,
        table2Hours: false
      };
    }
  }
};
</script>
