const ContenenceEnum = {
  Complete: 0,
  Urine: 1,
  Faeces: 2,
  Doubly: 3
};

export default {
  Enum: ContenenceEnum,
  Lookup: [
    { id: ContenenceEnum.Complete, value: "Complete / Catheterised" },
    { id: ContenenceEnum.Urine, value: "Incontinent urine" },
    { id: ContenenceEnum.Faeces, value: "Incontinent faeces" },
    { id: ContenenceEnum.Doubly, value: "Doubly incontinent (urine & faeces)" }
  ]
};
