const SkinTypeEnum = {
  Healthy: 0,
  TissuePaper: 1,
  Dry: 2,
  Oedematous: 3,
  Clammy: 4,
  Discoloured: 5,
  Broken: 6
};

export default {
  Enum: SkinTypeEnum,
  Lookup: [
    { id: SkinTypeEnum.Healthy, value: "Healthy" },
    { id: SkinTypeEnum.TissuePaper, value: "Tissue paper (thin/fragile)" },
    { id: SkinTypeEnum.Dry, value: "Dry (appears flaky)" },
    { id: SkinTypeEnum.Oedematous, value: "Oedematous (puffy)" },
    { id: SkinTypeEnum.Clammy, value: "Clammy (moist to touch)/pyrexia" },
    { id: SkinTypeEnum.Discoloured, value: "Discoloured (bruising/mottled)" },
    { id: SkinTypeEnum.Broken, value: "Broken (established ulcer)" }
  ],
  Score: [0, 1, 1, 1, 1, 2, 3]
};
